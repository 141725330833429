import React from 'react'
import { CheckOut } from '../Views'
import { navigate } from "gatsby";
import SEO from '../components/seo';

function checkout() {
    return (
        <div>
            <SEO title="Check Out" />
            <CheckOut />
        </div>
    )
}

export default checkout
